<template>
  <div class="reserved-area-page">
    <div class="container">
      <div class="d-xl-flex">
        <div class="reserved-area-page-column">
          <reservedAreaMenu/>
        </div>
        <div class="reserved-area-page-column">

          <form class="d-flex justify-content-between flex-column">
            <div class="reserved-area-form-group-wrapper">
              <div class="reserved-area-form-group">
                <sliderFilter/>
                <div class="reserved-area-workout-list">
                  <div class="reserved-area-exercise-item">
                    <label>
                      <div class="d-flex justify-content-between align-items-start">
                        <div class="d-flex justify-content-start align-items-start">
                          <div class="reserved-area-workout-image">
                            <img src="http://via.placeholder.com/50" alt="">
                          </div>
                          <div class="reserved-area-workout-info">
                            <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                            <div class="workout-item-tags">
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <clock/>
                                </div>
                                <p>15 min.</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <level/>
                                </div>
                                <p>Intermédio</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <fire/>
                                </div>
                                <p>Iniciante</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="reserved-area-exercise-item-checkbox">
                          <input type="checkbox">
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="reserved-area-exercise-item">
                    <label>
                      <div class="d-flex justify-content-between align-items-start">
                        <div class="d-flex justify-content-start align-items-start">
                          <div class="reserved-area-workout-image">
                            <img src="http://via.placeholder.com/50" alt="">
                          </div>
                          <div class="reserved-area-workout-info">
                            <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                            <div class="workout-item-tags">
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <clock/>
                                </div>
                                <p>15 min.</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <level/>
                                </div>
                                <p>Intermédio</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <fire/>
                                </div>
                                <p>Iniciante</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="reserved-area-exercise-item-checkbox">
                          <input type="checkbox">
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="reserved-area-exercise-item">
                    <label>
                      <div class="d-flex justify-content-between align-items-start">
                        <div class="d-flex justify-content-start align-items-start">
                          <div class="reserved-area-workout-image">
                            <img src="http://via.placeholder.com/50" alt="">
                          </div>
                          <div class="reserved-area-workout-info">
                            <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                            <div class="workout-item-tags">
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <clock/>
                                </div>
                                <p>15 min.</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <level/>
                                </div>
                                <p>Intermédio</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <fire/>
                                </div>
                                <p>Iniciante</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="reserved-area-exercise-item-checkbox">
                          <input type="checkbox">
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="reserved-area-exercise-item">
                    <label>
                      <div class="d-flex justify-content-between align-items-start">
                        <div class="d-flex justify-content-start align-items-start">
                          <div class="reserved-area-workout-image">
                            <img src="http://via.placeholder.com/50" alt="">
                          </div>
                          <div class="reserved-area-workout-info">
                            <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                            <div class="workout-item-tags">
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <clock/>
                                </div>
                                <p>15 min.</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <level/>
                                </div>
                                <p>Intermédio</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <fire/>
                                </div>
                                <p>Iniciante</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="reserved-area-exercise-item-checkbox">
                          <input type="checkbox">
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="reserved-area-exercise-item">
                    <label>
                      <div class="d-flex justify-content-between align-items-start">
                        <div class="d-flex justify-content-start align-items-start">
                          <div class="reserved-area-workout-image">
                            <img src="http://via.placeholder.com/50" alt="">
                          </div>
                          <div class="reserved-area-workout-info">
                            <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                            <div class="workout-item-tags">
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <clock/>
                                </div>
                                <p>15 min.</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <level/>
                                </div>
                                <p>Intermédio</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <fire/>
                                </div>
                                <p>Iniciante</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="reserved-area-exercise-item-checkbox">
                          <input type="checkbox">
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="reserved-area-exercise-item">
                    <label>
                      <div class="d-flex justify-content-between align-items-start">
                        <div class="d-flex justify-content-start align-items-start">
                          <div class="reserved-area-workout-image">
                            <img src="http://via.placeholder.com/50" alt="">
                          </div>
                          <div class="reserved-area-workout-info">
                            <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                            <div class="workout-item-tags">
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <clock/>
                                </div>
                                <p>15 min.</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <level/>
                                </div>
                                <p>Intermédio</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <fire/>
                                </div>
                                <p>Iniciante</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="reserved-area-exercise-item-checkbox">
                          <input type="checkbox">
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="reserved-area-exercise-item">
                    <label>
                      <div class="d-flex justify-content-between align-items-start">
                        <div class="d-flex justify-content-start align-items-start">
                          <div class="reserved-area-workout-image">
                            <img src="http://via.placeholder.com/50" alt="">
                          </div>
                          <div class="reserved-area-workout-info">
                            <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                            <div class="workout-item-tags">
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <clock/>
                                </div>
                                <p>15 min.</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <level/>
                                </div>
                                <p>Intermédio</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <fire/>
                                </div>
                                <p>Iniciante</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="reserved-area-exercise-item-checkbox">
                          <input type="checkbox">
                        </div>
                      </div>
                    </label>
                  </div>
                  <div class="reserved-area-exercise-item">
                    <label>
                      <div class="d-flex justify-content-between align-items-start">
                        <div class="d-flex justify-content-start align-items-start">
                          <div class="reserved-area-workout-image">
                            <img src="http://via.placeholder.com/50" alt="">
                          </div>
                          <div class="reserved-area-workout-info">
                            <p class="reserved-area-workout-title">Rebound Medicine Ball</p>
                            <div class="workout-item-tags">
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <clock/>
                                </div>
                                <p>15 min.</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <level/>
                                </div>
                                <p>Intermédio</p>
                              </div>
                              <div class="workout-item-tag-item d-flex justify-content-center align-items-center">
                                <div class="workout-item-tag-item-icon d-flex justify-content-center align-items-center">
                                  <fire/>
                                </div>
                                <p>Iniciante</p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="reserved-area-exercise-item-checkbox">
                          <input type="checkbox">
                        </div>
                      </div>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="reserved-area-form-btn-wrapper d-flex justify-content-center align-items-center">
              <button type="submit" class="reserved-area-form-btn generic-btn red-gradient ">Adicionar Exercícios</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
//Components
import reservedAreaMenu from "@/components/reserved-area/reserved-area-menu.vue";
import sliderFilter from "@/components/slider-filter.vue";
import clock from "@/components/svg/clock.vue";
import level from "@/components/svg/level.vue";
import fire from "@/components/svg/fire.vue";

export default {
  name: "reserved-area-creator-add-exercises",
  components : {
    reservedAreaMenu,
    sliderFilter,
    clock,
    level,
    fire,
  },
}
</script>