<template>
  <div class="slider-filter-wrapper d-flex justify-content-between align-items-center">
    <button type="button" class="slider-filter-arrow slider-filter-arrow-prev d-flex justify-content-center align-items-center justify-content-xl-start" @click="prevSlide">
      <div class="slider-filter-arrow-icon">
        <chevronLeft/>
      </div>
    </button>
    <div class="slider-filter-slider-wrapper">
      <Slick ref="slick" :options="slickOptions" class="slider-filter-slider">
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>A</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>B</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>C</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>D</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>E</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>F</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>G</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>H</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>I</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>J</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>K</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>L</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>M</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>N</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>O</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>P</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>Q</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>R</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>S</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>T</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>W</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>U</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>V</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>X</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>Y</p>
          </div>
        </div>
        <div class="slider-filter-slider-item">
          <div class="slider-filter-slider-item-content" @click="changedLibraryFilter">
            <p>Z</p>
          </div>
        </div>
      </Slick>
    </div>
    <button type="button" class="slider-filter-arrow slider-filter-arrow-next d-flex justify-content-center align-items-center justify-content-xl-start" @click="nextSlide">
      <div class="slider-filter-arrow-icon">
        <chevronRight/>
      </div>
    </button>
  </div>
</template>

<script>
import Slick from "vue-slick";
import chevronLeft from "@/components/svg/chevron-left.vue";
import chevronRight from "@/components/svg/chevron-right.vue";
import {EventBus} from "@/event-bus";

export default {
  name: "slider-filter",
  components: {
    Slick,
    chevronLeft,
    chevronRight,
  },
  data: function () {
    return {
      slickOptions: {
        slidesToScroll: 1,
        pauseOnHover: true,
        autoplay: false,
        arrows: false,
        dots: false,
        infinite: false,
        swipeToSlide: true,
        touchMove: true,
        speed: 100,
        variableWidth: true,
      },
    }
  },
  methods: {
    prevSlide: function() {
      this.$refs.slick.prev();
    },
    nextSlide: function() {
      this.$refs.slick.next();
    },
    changedLibraryFilter(e) {
      let otherElements = document.querySelectorAll('.slider-filter-slider-item-content');
      let element = e.currentTarget;
      let filter = '';

      if ( element.classList.contains('filter-active') ) {
        element.classList.remove('filter-active');
      } else {
        otherElements.forEach(node => {
          node.classList.remove('filter-active');
        });

        element.classList.add('filter-active');

        filter = element.querySelector('p').textContent;
      }

      EventBus.$emit("bus-libraryFilter", filter);
    }
  },
}
</script>